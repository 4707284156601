<template>
  <common-page-table
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :apiDeleteName="apiDeleteName"
    :titleTable="$t('Атрибуты объектов')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :prefixEdit="`/attrentity`"
    :prefixShow="`/attrentity`"
    :linkCreate="`/attrentity/create`"
    :keyTable="'attrEntityId'"
  />
</template>
<script>
import { NAME_OBJECT, LIST_EQUIVALENT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS } from "../../../../constant";
export default {
  name:"TablePageAttrEntity",
  components: { CommonPageTable },
  data() {
    return {
      fieldLang: '',
      apiName: API_METHODS.GET_ALL_ATTR_ENTITY,
      apiDeleteName: API_METHODS.DELETE_ATTR_ENTITY
    };
  },
  computed: {
    paramsGetData() {
      return {  id: this.$route.params['AttrEntityId']  };
    },
    nameRoleEntity() {
      return NAME_OBJECT.attrEntity
    },
  }
};
</script>
